import Env from '@env';
import texts from './texts'
import mq from './mq'
import range from 'lodash/range'
import { isMobile } from '../../libs/uaparser'
// dev
// -------------------------------------------
// Use by default
// ...dev(),
// User with a custom color
// ...dev('cyan'),
const dev = (color = '#f06') => ({
  outline: `8px solid ${color}`,
  outlineOffset: '-10px',
})

// font
// -------------------------------------------
// !important NOT IN USE
// Use by default
// ...font(),
// User with custom options
// ...font('stepUp5', { fontWeight: theme.fontWeight.extrabold }),
const font = (family = 'stepUp5', options) => {
  return {
    ...texts[family],
    ...options,
    color: 'cyan',
  }
}

// textOverflow
// ----------------------------------------
// Use by default
// ...textOverflow(),
// Use width max-width = 200px
// ...textOverflow(200),
const textOverflow = (width = '100%') => ({
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  maxWidth: width,
})

// centered
// -------------------------------------------
// Use by default
// ...centered(),
// User with absolute position
// ...centered('absolute'),
const centered = (position = 'relative') => ({
  position: position,
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
})

// hoverDevice
// ----------------------------------------
// Mediaquery used for devices that can hover over elements (with mouse)
// [hoverDevice()]: {
//   '&:hover': {
//     opacity: '0.7',
//   }
// }
const hoverDevice = (hover = 'hover') => {
  return `@media (hover: ${hover})`
}

// supports
// ----------------------------------------
// Add an @at-rule (supports) evaluation
// @param {string} -> prop to evaluate
// @param {boolean} -> evaluates if it has or not support for the prop
// [supports('display: grid')]: {
//  Your rules
// }
const supports = (prop, support = true) => {
  return `@supports ${support ? '' : 'not'} (${prop})`
}

// shadows
// -------------------------------------------
// Add shadows to tables that overflows when scrolling
// ...shadows(theme),
const shadows = theme => ({
  position: 'relative',

  '& > div': {
    overflowX: 'auto',
  },

  '&::before, &::after': {
    content: '""',
    display: 'block',
    width: '3rem',
    height: '100%',
    position: 'absolute',
    top: 0,
    opacity: 0,
    pointerEvents: 'none',
    transition: 'opacity 150ms',
  },
  '&::before': {
    left: 0,
    backgroundImage: `linear-gradient(90deg, ${theme.custom.palette.quaternary700_87} 0%, ${theme.custom.palette.quaternary700_0} 100%)`,
  },
  '&::after': {
    right: 0,
    backgroundImage: `linear-gradient(90deg, ${theme.custom.palette.quaternary700_0} 0%, ${theme.custom.palette.quaternary700_87} 100%)`,
  },

  '&$shadowLeft': {
    '&::before': {
      opacity: 1,
    },
  },
  '&$shadowRight': {
    '&::after': {
      opacity: 1,
    },
  },
})

/* ASPECT RATIO */

/**
 * Calculate percentage
 * @param aspectRatio {string} -> '16 / 9'
 * @returns {string}
 */
const calculateRatio = aspectRatio => {
  const [num1, num2] = aspectRatio.split('/').map(n => Number(n))
  const percentage = (num2 * 100) / num1
  return percentage
}

/**
 * getAspectRatio - get the aspect ratio prop value for a given breakpoint, or
 * optionally the default value
 * @param {String} breakpoint - breakpoint ('xxs', 'xs', 'sm', 'md', 'lg', 'xl')
 * @param {Boolean} fallback - whether or not to return the default aspect ratio,
 * i.e. the value when the aspectRatio prop is not an object. Defaults to true
 * @return {Function} a function that receives the props of a component and
 * returns its aspect ratio value for the given endpoint
 */
const getAspectRatio = (breakpoint, fallback = true) => props => {
  const { aspectRatio } = props

  if (!aspectRatio) return undefined

  if (typeof aspectRatio === 'object') {
    if (aspectRatio[breakpoint]) return aspectRatio[breakpoint]
  } else if (fallback) {
    return aspectRatio
  }

  return undefined
}

const getPaddingFromAspectRatio = (breakpoint, fallback?: boolean) => {
  const aspectRatioGetter = getAspectRatio(breakpoint, fallback)

  return props => {
    const aspectRatio = aspectRatioGetter(props)
    if (aspectRatio) return `${calculateRatio(aspectRatio) / 2}%`
  }
}

/**
 * aspectRatio - return style rules for applying aspect ratio to HTML elements,
 * using aspect-ratio by default but falling back to a hack using ::before
 * pseudo-element if the browser does not support aspect-ratio
 * @usage spread the result os calling this function inside style declarations
 * ...aspectRatio()
 */
const aspectRatio = () => ({
  width: '100%',
  height: 'auto',
  aspectRatio: getAspectRatio('xs'),

  [mq('sm')]: {
    aspectRatio: getAspectRatio('sm', false),
  },

  [supports('aspect-ratio: 1/1', false)]: {
    position: 'relative',

    '&::before': {
      content: '""',
      width: '100%',
      height: '100%',
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
    },

    paddingBottom: getPaddingFromAspectRatio('xs'),
    paddingTop: getPaddingFromAspectRatio('xs'),

    [mq('sm')]: {
      paddingBottom: getPaddingFromAspectRatio('sm', false),
      paddingTop: getPaddingFromAspectRatio('sm', false),
    },
  },
})

const getBreakpoint = offsetWidth => {
  switch (true) {
    case range(100, 131).includes(offsetWidth):
      return 'xs'
    case range(131, 156).includes(offsetWidth):
      return 'sm'
    case range(156, 237).includes(offsetWidth):
      return 'md'
    case range(237, 501).includes(offsetWidth):
      return 'lg'
    default:
      return 'md'
  }
}

const reArrangementResults = prop => prop.reverse().slice(0, 5)

//logic for amber layout check

const amberLayoutBrands: Record<string, boolean> = {
  bolnasite: true,
  sbnasite: true,
  lvnasite: true
}
const isAmberLayout = () =>
  amberLayoutBrands.hasOwnProperty(Env.get('VITE_GSETTING'))

/**
 * Gets the base subpath for the casino.
 * Works in both library mode (imported into Venus) and standalone mode.
 * Uses '/casino' if VITE_VENUS_CASINO_101 is not set.
 * @returns {string} the base path for the casino routes
 */
const getCasinoSubPath101 = () => Env.get('VITE_VENUS_CASINO_101') ?? '/casino/'
const getBasePath = () =>  isMobile()? Env.get('VITE_VENUS_BASE_PATH') ?? '' : ''


export {
  mq,
  textOverflow,
  centered,
  supports,
  dev,
  font,
  shadows,
  aspectRatio,
  hoverDevice,
  getBreakpoint,
  reArrangementResults,
  isAmberLayout,
  getCasinoSubPath101,
  getBasePath
}

import Env from '@env';
import React, { Suspense, useEffect, useState } from 'react'
import { Route, Routes } from 'react-router-dom'

import LoginSkeletonContext from './context/Login'
import { RedirectToJoin, RedirectToLogin } from './authentication'
import useVerifiedAgeCookie from './hooks/useVerifiedAgeCookie'
import { shouldUseNewLoginJoinLayout } from './config'
import { useIsBrand } from './hooks/useIsBrand'
import { isAmberLayout } from './ui/config/utils'
import { buildLinks } from './context/Layout/SportBettingLayout/utils'
import SAC from './LayoutRoutes/AmberLayoutRoutes'
import { isNewNavigationEnabled } from './context/kameleeon/experiments/newNavigation'
import { isMobile, isDesktop, isTablet } from './libs/uaparser'
import useStyles from './context/Layout/NewLayout/layout.styles'
import { useTheme } from '@material-ui/core'
import useFeatureFlags from './hooks/useFeatureFlag'
import { sideMenuContainerLeftTransition } from './hooks/useCustomEvents'
import useHandlerSocketConnection from './hooks/useHandlerSocketConnection'

// Lazy components
const LazyLegalAgeModal = React.lazy(
  () => import('./ui/components/LegalAgeModal'),
)
const LazyGlobalHeader = React.lazy(
  () => import('./ui/components/GlobalHeader'),
)
const LazyHeader = React.lazy(() => import('./ui/external-components/Header'))
const LazyMainMenu = React.lazy(
  () => import('./ui/external-components/MainMenu'),
)
const LazyAmberHeader = React.lazy(
  () => import('./context/Layout/SportBettingLayout/AmberHeader'),
)
const LazyAmberFooter = React.lazy(
  () => import('./context/Layout/SportBettingLayout/AmberFooter'),
)
const LazyDefaultFooter = React.lazy(
  () => import('./context/Layout/DefaultLayout/DefaultFooter'),
)
const LazyDefaultHeader = React.lazy(
  () => import('./context/Layout/DefaultLayout/DefaultHeader'),
)
const LazyNewHeader = React.lazy(
  () => import('./ui/components/NewNavigation/newHeader/NewHeader'),
)
const LazyBottomNav = React.lazy(
  () => import('./ui/components/NewNavigation/components/BottomNav'),
)
const LazySideMenu = React.lazy(
  () => import('./ui/components/NewNavigation/components/SideMenu'),
)
const LazyUniversal101 = React.lazy(
  () => import('./ui/external-components/Universal101'),
)
const LazySAC = React.lazy(() => import('./LayoutRoutes/AmberLayoutRoutes'))

const EXTERNAL_REDIRECT_URL = 'https://google.com'

const App = () => {
  useHandlerSocketConnection()
  const { isBOL, isSB, isQBC, isLV } = useIsBrand()
  const allowHealthPath = isBOL() || isQBC() || isLV()

  const FallbackHeader = isBOL() || isSB() ? LazyHeader : LazyGlobalHeader

  const [isVerified, isRejected, accept, reject] = useVerifiedAgeCookie()
  const { isNewDesktopEnabled } = useFeatureFlags()

  const siteKey = Env.get('VITE_GSETTING')
  const isGamingCity = siteKey === 'gcsite'
  const isPrerender = navigator.userAgent.includes('Prerender')
  const needAgeVerification = isGamingCity && !isVerified() && !isPrerender

  const [navigationItems] = useState(buildLinks(siteKey))
  const [isNewNav, setIsNewNav] = useState(true)
  const [menuState, setMenuState] = useState(false)
  const classes = useStyles({ menuState })

  const theme: {
    custom: {
      hasExperimentsEnabled: boolean
      hasNewNavigation: boolean
    }
  } = useTheme()
  useEffect(() => {
    if (isRejected()) {
      window.location.href = EXTERNAL_REDIRECT_URL
    }
  }, [isRejected])

  const initKameleoon = async () => {
    try {
      const FFEnabled = await isNewNavigationEnabled()
      setIsNewNav(FFEnabled)
    } catch (error) {
      console.error(error)
    }
  }
  useEffect(() => {
    if (theme.custom?.hasExperimentsEnabled) {
      initKameleoon()
    }
  }, [])

  const showNewNavComponentsMobile =
    theme.custom?.hasNewNavigation && isMobile()
  const showNewNavDesktop =
    theme.custom?.hasNewNavigation &&
    (isDesktop() || isTablet()) &&
    isNewDesktopEnabled

  const useNewLoginJoinLayout = shouldUseNewLoginJoinLayout()

  const checkKeycloakStatusAfterChangingPassword = () => {
    const isProfilePath = window.location.pathname.includes(
      '/my-account/profile',
    )
    if (isProfilePath) {
      let params = new URLSearchParams(window.location.search)
      const kcActionStatus = params.get('kc_action_status')
      if (kcActionStatus) {
        sessionStorage.setItem('change_password', kcActionStatus)
      }
    }
  }

  checkKeycloakStatusAfterChangingPassword()

  // App Elements

  const AppHeader = () =>
    isAmberLayout() ? (
      <>
        <LazyAmberHeader />
        <LazyMainMenu navigationItems={navigationItems} />
      </>
    ) : (
      <LazyDefaultHeader />
    )

  const AppFooter = () =>
    isAmberLayout() ? <LazyAmberFooter /> : <LazyDefaultFooter />

  return (
    <>
      <Routes>
        {useNewLoginJoinLayout && (
          <Route
            path="/login"
            element={
              !needAgeVerification ? (
                <RedirectToLogin>
                  <LoginSkeletonContext />
                </RedirectToLogin>
              ) : (
                <LoginSkeletonContext />
              )
            }
          ></Route>
        )}

        {useNewLoginJoinLayout && (
          <Route
            path="/join"
            element={
              !needAgeVerification ? (
                <RedirectToJoin>
                  <LoginSkeletonContext />
                </RedirectToJoin>
              ) : (
                <LoginSkeletonContext />
              )
            }
          ></Route>
        )}

        {allowHealthPath && (
          <Route path="/health" element={<p>Status: ok</p>}></Route>
        )}

        <Route
          path="*"
          element={
            <Suspense
              fallback={
                <FallbackHeader
                  logo={`/logo.svg`}
                  logoSlim={`/logo-slim.svg`}
                ></FallbackHeader>
              }
            >
              {showNewNavComponentsMobile || showNewNavDesktop ? (
               <Suspense>
                  <LazyNewHeader />
                </Suspense>
              ) : (
                <Suspense>
                  <AppHeader />
                </Suspense>
              )}
              {(showNewNavComponentsMobile || showNewNavDesktop) && (
                <>
                  {/* @ts-expect-error Optional property children */}
                  <LazySideMenu sideNavTrigger={setMenuState} />
                  </>
              )}
              <div
                className={`${classes.appFluidContainer} ${
                  showNewNavDesktop ? classes.contentShift : ''
                }`}
                onTransitionEnd={sideMenuContainerLeftTransition}
              >
                <LazySAC />
                <Suspense>
                <LazyUniversal101 />
                <AppFooter />
                </Suspense>
              </div>
              {showNewNavComponentsMobile && <LazyBottomNav />}
            </Suspense>
          }
        ></Route>
      </Routes>
      {needAgeVerification && (
        <Suspense>
        <LazyLegalAgeModal onSubmit={isAdult => (isAdult ? accept() : reject())} />
        </Suspense>
      )}
    </>
  )
}

export default App
